<template>
<router-view v-if="init"></router-view>
</template>

<script>
// vue
import { ref, onMounted } from 'vue';

// services
import BotsService from '@services/bots';
import ToolsService from '@services/tools';

// store
import { useGl } from '@/store/gl';
import { useBots } from '@store/bots';
import { useTools } from '@store/tools';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NSpin,
    useMessage } from 'naive-ui';

export default {
    name: 'Bots',
    components: {
        NSpin,
    },
    setup() {
        // store
        const gl = useGl();
        const tools = useTools();
        const bots = useBots();

        // vars
        const init = ref(false);

        // i18n
        const { t } = useI18n();

        // ui
        

        const getData = async () => {
            gl.showLoading = true;

            try {
                if (!tools.statsFullInfo)
                    tools.statsFullInfo = ( await ToolsService.getStatsFullInfo({ stat_period: 1 }) ).data;

                bots.refs = ( await BotsService.getRefs() ).data;

            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            };

            gl.showLoading = false;
        };

        onMounted(async () => {
            await getData();

            init.value = true;
        });

        return {
            init,
        };
    },
};
</script>